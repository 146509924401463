
export default {
    name: 'AddressSubmit',
    props: {
        form: Object,
        check: Object,
        middleEmit: Object,
        country: Object,
        query: Object
    },
    data() {
        return {
            ref: {},
            // country: {},
            // query: {},
            hasLogin: false,
            hasOrderPage: false, // 是否内嵌在下单页
            resultAddress: {},
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 设置地址
        setAddressAjax(type, form) {
            this.$api.address[type](form, !this.hasOrderPage).then(response => {
                if(response.code != 'success') return
                const res = response.result || {};
                this.resultAddress = res === true ? this.form : res || {}
                this.resultAddress.englishName = this.country.englishName
                this.resultAddress.showName = this.country.showName||this.country.englishName
                // 保存后操作
                this.setAddressResult()
            })
        },
        // 提交
        handleSubmit() {
            this.getRef()
            this.middleEmit.fn('handleSave')
            this.checkForm().then(res => {
                if(this.check.hasCheck) {
                    // 设置保存数据
                    this.setSaveData()
                }
            })
        },
        // 校验
        getRef() {
            this.ref = this.middleEmit.fn('getRef')
        },
        checkForm() {
            return new Promise((resolve, reject) => {
                let checkList = ['AddressBasic', 'AddressLine'];
                checkList.forEach(key => {
                    this.ref[key].$refs['form'].submit()
                })
                let clear = setTimeout(() => {
                    clearTimeout(clear)
                    this.check.hasCheck = this.check.hasBasic && this.check.hasAddress
                    resolve(this.check.hasCheck)
                }, 10)
            })
        },
        // 保存数据
        setSaveData() {
            // 更新地址
            let type = 'setAdd'; // 新增地址
            if (this.query.key.includes('editAddress')) { // 更改地址
                type = 'setEdit'
            }
            if(this.query.key.includes('order/billAddress')) { // 账单地址
                this.form.orderId = this.query.order_id
                type = 'setEditBill'
            }
            let form = JSON.parse(JSON.stringify(this.form));
            // 设置手机区号
            if(this.form.phone) {
                form.phone = `${this.check.rules.mobileCode}-${this.form.phone}`
            }
            this.setAddressAjax(type, form)
        },
        // 保存后操作
        setAddressResult() {
            let key = this.query.key; // noLoginAddress 未登录地址 editAddress 编辑地址 order/billAddress 账单地址 order/placeAddress 下单地址
            let keyData = {};
            let form = JSON.parse(JSON.stringify(this.form));
            keyData = form
            // 手机区号处理
            if(this.form.phone) {
                form.phone = `${this.check.rules.mobileCode}-${this.form.phone}`
            }
            // 未登录地址
            if(!this.hasLogin && !this.query.key.includes('order/billAddress')) {
                this.$storage.set('noLoginAddress', {
                    ...this.resultAddress,
                    ...form
                })
                this.$storage.set('userId', this.resultAddress.userId, true)
            }
            key && this.$storage.set(key, keyData)
            // 内嵌在下单页
            if(this.hasOrderPage) return
            // 下单
            if(this.query.key.includes('order/placeAddress')) {
                if(this.query.from) {
                    this.$router.push({
                        name: 'OrderConfirm',
                        query: {
                            from: this.query.from
                        }
                    })
                } else {
                    this.$router.back()
                }
            } else {
                this.$router.back()
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.hasOrderPage = this.$route.name == 'OrderConfirm'
            // this.query = this.$route.query || {}
            // this.query.key ?? (this.query.key = '')
            // this.country = this.$storage.get('country') || {}
        }
    },
}
