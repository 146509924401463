
import fnApi from '/utils/common/methods'
import AddressTip from './components/AddressTip'
import AddressBasic from './components/AddressBasic'
import AddressLine from './components/AddressLine'
import AddressDefault from './components/AddressDefault'
import AddressPopup from './components/AddressPopup'
import AddressSubmit from './components/AddressSubmit'

export default {
    name: 'Address',
    components: {
        AddressTip,
        AddressBasic,
        AddressLine,
        AddressDefault,
        AddressPopup,
        AddressSubmit
    },
    data() {
        return {
            title: '',
            middleEmit: {},
            skeleton: {              // 骨架屏
                visible: true,
            },
            hasLogin: false,         // 是否登录
            user: {},                // 用户信息
            country: {},             // 国家
            query: {},               // 系列参数 -> type 1 不可更改国家
            target: {},              // 当前操作项
            check: {                 // 校验
                hasCheck: false,     // 校验是否通过
                hasBasic: false,     // 基础信息校验
                hasAddress: false,   // 地址信息校验
                hasState: false,     // 当前国家是否存在省
                hasCity: false,      // 当前国家省份是否存在市
                rules: {},           // 校验规则
                hasChange:false,     //用户是否有输入
            },
            addResult: {},           // 保存后结果地址数据
            form: {
                city: '',
                state: ''
            },                // 表单
            popup: {
                visible: false,      // 弹窗
            },
            back: {
                hasBack: false,
                visible: false
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    beforeRouteEnter (to, from, next) {
        from.name == 'Country' && location.reload()
        next()
    },
    created() {},
    mounted() {
        this.initialSet()
        this.getUserInfo()
        this.getStateAjax()
        this.setAddListener()
    },
    destroyed() {
        window.removeEventListener('popstate', this.setBack, false)
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            if(!this.hasLogin) {
                return
            }
            this.$api.user.getUseInfo({
                id: this.user.userId
            }).then(response => {
                const res = response.result || {};
                this.user = res || {}
                this.form.email = this.user.email
            })
        },
        // 获取当前国家州/省
        getStateAjax() {
            this.$api.common.getState({
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response.result || {};
                this.check.hasState = !!res.length
                this.check.hasCity = this.check.hasState
                this.getCheckRulesAjax()
            })
        },
        // 获取校验规则
        getCheckRulesAjax() {
            this.$api.address.getRules({
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response.result || {};
                this.skeleton.visible = false
                fnApi.debounce(() => {
                    this.check.rules = res || {}
                }, 10)
            })
        },
        // 操作
        emitHandleSet(obj) {
            this.target = obj || {}
            // 国家设置
            if(this.target.key == 'showName') {
                let query ={}
                if(this.query?.key == 'editAddress')query = { key: 'editAddress'}
                if(['order/placeAddress', 'order/billAddress'].includes(this.query.key)) {
                    this.$router.push({
                        name: 'CountryAddress',
                        query: { 
                            key: 'editAddress',
                            englishName:this.country?.englishName,
                            countryCode:this.country?.countryCode
                        }
                    })
                    // this.$Toast(this.$translate('If you want to switch to another country, please go to your personal certer and make the change there'))
                } else {
                    this.$router.push({
                        name: 'CountryAddress',
                        query: { 
                            key: 'editAddress',
                            englishName:this.country?.englishName,
                            countryCode:this.country?.countryCode
                        }
                    })
                }
                return
            }
            // 身份证号
            if(this.target.key == 'nationalId') {
                this.popup.visible = true
                return
            }
            // 州
            if(['state', 'city'].includes(this.target.key)) {
                // this.regionCompo.visible = true
                this.regionCompo.type = ['state', 'city'].indexOf(this.target.key) + 1
                return
            }
        },
        getRef() {
            const ref = this.$refs;
            return ref
        },
        // 返回操作
        setAddListener() {
            const hasRefresh = this.$store.state.biz.hasRefresh;
            if(window.history && window.history.pushState && this.query.from != 'afterAddCart') {
                !hasRefresh && history.pushState(null, null, document.URL);
                window.addEventListener('popstate', this.setBack, false)
            }
        },
        setBack() {
            if(this.back.hasBack || !this.check.hasChange) {
                if(this.query.from == 'giftCard') {
                    this.$router.push({
                        name: 'GiftCard'
                    })
                } else {
                    this.$router.back()
                }
            } else {
                history.pushState(null, null, document.URL);
                this.back.visible = true
                this.back.hasBack = false
                return false
            }
        },
        handleBackNo() {
            this.back.hasBack = true
            this.back.visible = false
            this.setBack()
        },
        handleBackKeep() {
            this.back.visible = false
            this.back.hasBack = false
        },
        handleClose(){
            this.setBack()
        },
        handleSave() {
            this.back.hasBack = true
            this.back.visible = false
        },
        getContainer() {
            return document.querySelector('.address')
        },
        // 地址内嵌在下单页
        setEmbedInitial(obj) { // 内嵌-数据处理
            this.skeleton.visible = false
            this.initialSet(obj)
        },
        setEmbedData(obj) {
            this.query.order_id = obj.order_id
        },
        // SEO HEAD
        setHead() {
            const hasOrderPage = this.$route.name == 'OrderConfirm';
            if(hasOrderPage) return {}
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shipping Address'),
                keywords: this.$translate('Shipping Address'),
                description: this.$translate('Shipping Address'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            const script = [{
                src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCCvuiILNztg_3we-PtIf3ZZqG5l1m1N4M&libraries=places',
                async: true,
                defer: true,
                body: true,
            }]
            return {
                ...head,
                script
            }
        },
        // 初始化
        initialSet(obj = {}) {
            // query-> type 0 可以更改国家 1 不能更改国家 、country 1 使用当前操作的国家、key 使用对应的地址
            let storage  = this.$storage.getAll();
            this.query = {
                ...this.$route.query,
                ...obj.query,
            }
            this.query.key ?? (this.query.key = '')
            this.country = this.$storage.get('countryStorage') || this.$storage.get('country') || {}
            this.hasLogin = this.$login()
            this.user.userId = storage.userId || ''
            this.form = storage[this.query.key] || this.form
            if(this.form.countryCode) {
                this.country.countryCode = this.form.countryCode
                this.country.englishName = this.form.englishName
            }
            this.form.country = this.country.countryCode
            this.form.englishName = this.country.englishName
            this.form.showName = this.country.showName||this.country.englishName
            // 设置头
            this.title = this.$translate('Shipping Address')
            if(['editAddress'].includes(this.query.key)) {
                this.title = this.$translate('Edit Shipping Address')
            }
            if(['order/placeAddress'].includes(this.query.key) && !storage[this.query.key]) {
                this.title = this.$translate('Add Shipping Address')
            }
            if(['giftCard'].includes(this.query.from)) {
                this.title = this.$translate('Add Billing Address')
            }
            if(this.query.key.includes('order/billAddress')) {
                this.title = this.$translate('Edit Billing Address')
            }
            // 设置区号
            if(this.form.phone && this.form.phone != 'undefined') {
                let reForm = JSON.parse(JSON.stringify(this.form))
                if(reForm.phone.match(/-(\S*)/)) {
                    this.form.phone = reForm.phone.match(/-(\S*)/)[1]
                }
            }
            this.middleEmit.fn = (fn, options) => {
                return this[fn](options)
            }
        }
    }
}
